.padding-top-2px {
    padding-top: 2px !important;
}

.padding-top-4px {
    padding-top: 4px !important;
}

.padding-top-10px {
    padding-top: 10px !important;
}

.customDatePickerWidth, .customDatePickerWidth>div.react-datepicker-wrapper, .customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0.25rem;
    border: 1px solid #e4e7ea;
    background-clip: padding-box;
    padding-left: 10px !important;
}

.react-datepicker__triangle {
    left: -9px !important;
}

/* html, body {
    height: 100%;
    padding: 10px;
    margin: 0;
    background-color: rgb(240, 240, 240);
    font-family: Calibri, Roboto, Sans-Serif;
} */

.formtoggle {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.4);
    text-align: center;
    position: relative;
    border-radius: 2px;
}

.formtoggle>div {
    color: white;
    padding-top: 24px;
    display: block;
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    width: calc(33.33% + 8px);
    background-color: rgba(50, 150, 255, 1);
    border-radius: 2px;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    pointer-events: none;
    transition: transform 0.3s;
    transform: translateX(calc(100% - 8px));
}

.formtoggle::after {
    content: "";
    display: block;
    clear: both;
}

.formtoggle label {
    float: left;
    width: calc(33.333% - 1px);
    position: relative;
    padding: 1px 0px 26px;
    overflow: hidden;
    border-left: solid 1px rgba(0, 0, 0, 0.2);
    transition: color 0.3s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.formtoggle label:first-child {
    border-left: none;
}

.formtoggle label input {
    position: absolute;
    top: -200%;
}

.formtoggle label div {
    z-index: 5;
    position: absolute;
    width: 100%;
    margin-top: 5px;
}

.formtoggle label.selected {
    color: white;
}

.ant-picker-range{
    width: 100% !important;
}

.ant-space-vertical{
    width: 100% !important;
}

#grouped{
    display: flex;
    align-items: center ;
}

#inputGroup{
    align-items: center;
}

#uploadLogoInput{
    border: 1px solid #eeebeb;
}

.file-upload-control #uploadLogoInput {
    border: 0;
}

.file-input {
  color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 1300px) {
    .react-bs-table-container {
        overflow: scroll;
    }

    .react-bs-table {
        min-width: 850px;
    }

  .react-bs-container-body {
    font-size: 12px;
  }
}